<template>
    <div class="modal fade" :id="modalId" tabindex="-1" :aria-labelledby="modalId + 'Label'" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel"> Promena lozinke </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <div style="text-align:center">
                        <div class="container col-md-10 d-grid gap-2">
                            <div v-if="user.role !== 'ADMIN'" class="form-group">
                                <input v-model="data.oldPassword" class="form-control" type="password" placeholder="Stara lozinka:" aria-label="default input example" minlength="6" required>
                                <div class="invalid-feedback">
                                    Lozinka mora sadržati najmanje 6 karaktera.
                                </div>
                            </div>

                            <div class="form-group">
                                <input v-model="data.newPassword" class="form-control" type="password" placeholder="Nova lozinka:" aria-label="default input example" minlength="6" required>
                                <div class="invalid-feedback">
                                    Lozinka mora sadržati najmanje 6 karaktera.
                                </div>
                            </div> 
                            <div class="form-group">
                                <input v-model="data.newPasswordConfirm" class="form-control" type="password" placeholder="Potvrdi novu lozinku:" aria-label="default input example" minlength="6" required>
                                <div class="invalid-feedback">
                                    Lozinka mora sadržati najmanje 6 karaktera.
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"> Zatvori </button>
                    <button @click="$emit('confirmed')" type="button" class="btn btn-primary"> Potvrdi </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {

    props: {
        modalId: String,
        data: Object
    },

    computed: {
        ...mapState([
            'user'
        ])
    },

}
</script>

<style>
p {
    font-size: 140%;
}
</style>