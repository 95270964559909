<template>
    <div>
        <div class="card mb-3">
            <div class="card-body">
                <!-- <pre>{{ vacation_progress }}</pre> -->

                <p>Slobodni dani</p>
                <template v-if="vacation_progress">
                    <h4 v-if="vacation_progress.free <= 0">Nemate više slobodnih dana</h4>
                    <h4 v-else-if="vacation_progress.free <= 8">Imate još od {{ vacation_progress.free }} slobodnih dana
                    </h4>
                    <div class="progress h-16" style="height: 24px;">
                        <div class="progress-bar bg-success" role="progressbar"
                            :style="'width: ' + vacation_progress.vacation_percent + '%'" aria-valuenow="30"
                            aria-valuemin="0" aria-valuemax="100">{{ vacation_progress.vacation }} odmora</div>
                        <div class="progress-bar" role="progressbar"
                            :style="'width: ' + vacation_progress.sickness_percent + '%'" aria-valuenow="15"
                            aria-valuemin="0" aria-valuemax="100">{{ vacation_progress.sickness }} bolovanja</div>
                        <div class="text-center bg-gray-50" role="progressbar"
                            :style="'width: ' + vacation_progress.free_percent + '%'" aria-valuenow="20"
                            aria-valuemin="0" aria-valuemax="100">{{ vacation_progress.free }} slobodno</div>
                    </div>
                </template>

            </div>

        </div>

        <div class="card mb-3">

            <div class="card-body">

                <!-- History Modal -->
                <div class="modal fade" id="modalDateHistory" tabindex="-1" aria-labelledby="modalDateHistoryLabel"
                    aria-hidden="true">
                    <ModalDateHistory v-bind:employee=employeeForHistory v-bind:dataName='dataName'
                        v-bind:historyData='historyData' />
                </div>

                <!-- Change Password -->
                <form id="formChangePassword">
                    <modal-change-password modalId="modalChangePassword" :data.sync="changePasswordData"
                        @confirmed="changePassword"></modal-change-password>
                </form>

                <!-- Edit Employee -->
                <form id="formEditEmployee">
                    <modal-add-edit-employee modalId="modalEditEmployee" title="Izmena radnika" mode="EDIT"
                        :employee.sync="employeeForUpdate" @confirmed="updateEmployee"></modal-add-edit-employee>
                </form>

                <div class="row">
                    <div class="col-sm-3">
                        <h6 class="mb-0">Ime</h6>
                    </div>
                    <div class="col-sm-9 text-secondary">
                        {{ employee.user.first_name }}
                    </div>
                </div>

                <hr>
                <div class="row">
                    <div class="col-sm-3">
                        <h6 class="mb-0">Prezime</h6>
                    </div>
                    <div class="col-sm-9 text-secondary">
                        {{ employee.user.last_name }}
                    </div>
                </div>

                <hr>
                <div class="row">
                    <div class="col-sm-3">
                        <h6 class="mb-0">Jmbg</h6>
                    </div>
                    <div class="col-sm-9 text-secondary">
                        {{ employee.jmbg ? employee.jmbg : "/" }}
                    </div>
                </div>

                <hr>
                <div class="row">
                    <div class="col-sm-3">
                        <h6 class="mb-0">Datum rođenja</h6>
                    </div>
                    <div class="col-sm-9 text-secondary">
                        {{ employee.birthdate }}
                    </div>
                </div>

                <hr>
                <div class="row">
                    <div class="col-sm-3">
                        <h6 class="mb-0">Mesto rođenja</h6>
                    </div>
                    <div class="col-sm-9 text-secondary">
                        {{ employee.birthplace }}
                    </div>
                </div>

                <hr>
                <div class="row">
                    <div class="col-sm-3">
                        <h6 class="mb-0">Broj telefona</h6>
                    </div>
                    <div class="col-sm-9 text-secondary">
                        {{ employee.phone }}
                    </div>
                </div>

                <hr>
                <div class="row">
                    <div class="col-sm-3">
                        <h6 class="mb-0">Email adresa</h6>
                    </div>
                    <div class="col-sm-9 text-secondary">
                        {{ employee.user.email }}
                    </div>
                </div>

                <hr>
                <div class="row">
                    <div class="col-sm-3">
                        <h6 class="mb-0">Uloga</h6>
                    </div>
                    <div class="col-sm-9 text-secondary">
                        {{ translateRole(employee.user.role) }}
                    </div>
                </div>

                <hr>
                <div class="row">
                    <div class="col-sm-3">
                        <h6 class="mb-0">Obrazovanje</h6>
                    </div>
                    <div class="col-sm-9 text-secondary">
                        {{ (employee.education) ? employee.education : "/" }}
                    </div>
                </div>

                <hr>
                <div class="row">
                    <div class="col-sm-3">
                        <h6 class="mb-0">Početak rada</h6>
                    </div>
                    <div class="col-sm-9 text-secondary">
                        <span>{{ (employee.work_start_date) ? formatDate(employee.work_start_date) : "/" }}</span>
                        <a v-if="employee.work_start_date" @click="showHistoryForWorkStartDate(employee)" href="#"
                            class="m-1 p-1"><i class="fas fa-history"></i></a>

                    </div>
                </div>

                <hr>
                <div class="row">
                    <div class="col-sm-3">
                        <h6 class="mb-0">Datum prijave</h6>
                    </div>
                    <div class="col-sm-9 text-secondary">
                        {{ (employee.application_start_date) ? formatDate(employee.application_start_date) : "/" }}
                        <a v-if="employee.application_start_date" @click="showHistoryForApplicationStartDate(employee)"
                            href="#" class="m-1 p-1"><i class="fas fa-history"></i></a>
                    </div>
                </div>

                <hr>
                <div class="row"
                    :class="{ 'warning-row': warnings.application_end_date >= 0, 'danger-row': warnings.application_end_date < 0 }">
                    <div class="col-sm-3">
                        <h6 class="mb-0"><i v-if="warnings.application_end_date"
                                class="fas fa-exclamation-triangle text-warning"> </i>Datum isteka prijave</h6>
                    </div>
                    <div class="col-sm-9 text-secondary">
                        <span>{{ (employee.application_end_date) ? formatDate(employee.application_end_date) :
                            "/" }}</span>
                        <a v-if="employee.application_end_date" @click="showHistoryForApplicationEndDate(employee)"
                            href="#" class="m-1 p-1"><i class="fas fa-history"></i></a>
                        <span v-if="warnings.application_end_date"><i
                                class="fas fa-exclamation-triangle text-warning"></i>{{
                                    getExpiredText(warnings.application_end_date) }}</span>
                    </div>
                </div>

                <hr>
                <div class="row"
                    :class="{ 'warning-row': warnings.work_end_date >= 0, 'danger-row': warnings.work_end_date < 0 }">
                    <div class="col-sm-3">
                        <h6 class="mb-0"><i v-if="warnings.work_end_date"
                                class="fas fa-exclamation-triangle text-warning">
                            </i>Kraj rada</h6>
                    </div>
                    <div class="col-sm-9 text-secondary">
                        <span>{{ (employee.work_end_date) ? formatDate(employee.work_end_date) : "/" }}</span>
                        <a v-if="employee.work_end_date" @click="showHistoryForWorkEndDate(employee)" href="#"
                            class="m-1 p-1"><i class="fas fa-history"></i></a>
                        <span v-if="warnings.work_end_date"><i class="fas fa-exclamation-triangle text-warning"></i>{{
                            getExpiredText(warnings.work_end_date) }}</span>
                    </div>
                </div>

                <hr>
                <div class="row">
                    <div class="col-sm-3">
                        <h6 class="mb-0">Tip prevoza</h6>
                    </div>
                    <div class="col-sm-9 text-secondary">
                        {{ (employee.transport_type) ? translateTransportType(employee.transport_type) : "/" }}
                    </div>
                </div>

                <hr>
                <div class="row">
                    <div class="col-sm-3">
                        <h6 class="mb-0">Radni status</h6>
                    </div>
                    <div class="col-sm-9 text-secondary">
                        {{ (employee.employee_status === 'ACTIVE') ? 'Aktivan' : 'Neaktivan' }}
                    </div>
                </div>

                <hr>
                <div class="row"
                    :class="{ 'warning-row': warnings.medical_examination_date >= 0, 'danger-row': warnings.medical_examination_date < 0 }">
                    <div class="col-sm-3">
                        <h6 class="mb-0"><i v-if="warnings.medical_examination_date"
                                class="fas fa-exclamation-triangle text-warning"> </i>Datum isteka lekarskog pregleda
                        </h6>
                    </div>
                    <div class="col-sm-9 text-secondary">
                        <span>{{ (employee.medical_examination_date) ? formatDate(employee.medical_examination_date) :
                            "/" }}</span>
                        <a v-if="employee.medical_examination_date"
                            @click="showHistoryForMedicalExaminationDate(employee)" href="#" class="m-1 p-1"><i
                                class="fas fa-history"></i></a>
                        <span v-if="warnings.medical_examination_date"><i
                                class="fas fa-exclamation-triangle text-warning"></i>{{
                                    getExpiredText(warnings.medical_examination_date) }}</span>
                    </div>
                </div>

                <hr>
                <div class="row"
                    :class="{ 'warning-row': warnings.health_card_expiration_date >= 0, 'danger-row': warnings.health_card_expiration_date < 0 }">
                    <div class="col-sm-3">
                        <h6 class="mb-0"><i v-if="warnings.health_card_expiration_date"
                                class="fas fa-exclamation-triangle text-warning"> </i>Datum isteka zdravstvene knjižice
                        </h6>
                    </div>
                    <div class="col-sm-9 text-secondary">
                        <span>{{ (employee.health_card_expiration_date) ?
                            formatDate(employee.health_card_expiration_date) : "/" }}</span>
                        <a v-if="employee.health_card_expiration_date"
                            @click="showHistoryForHealthCardExpirationDate(employee)" href="#" class="m-1 p-1"><i
                                class="fas fa-history"></i></a>
                        <span v-if="warnings.health_card_expiration_date"><i
                                class="fas fa-exclamation-triangle text-warning"></i>{{
                                    getExpiredText(warnings.health_card_expiration_date) }}</span>
                    </div>
                </div>

                <hr>
                <div class="row">
                    <div class="col-sm-3">
                        <h6 class="mb-0">Datum verskog praznika</h6>
                    </div>
                    <div class="col-sm-9 text-secondary">
                        {{ (employee.religious_holiday_date) ? formatDate(employee.religious_holiday_date) : "/" }}
                        <a v-if="employee.religious_holiday_date" @click="showHistoryForReligiousHoliday(employee)"
                            href="#" class="m-1 p-1"><i class="fas fa-history"></i></a>
                    </div>
                </div>

                <hr>
                <div class="row">
                    <div class="col-sm-3">
                        <h6 class="mb-0">Broj cipela</h6>
                    </div>
                    <div class="col-sm-9 text-secondary">
                        {{ (employee.shoe_size) ? employee.shoe_size : "/" }}
                    </div>
                </div>

                <hr>
                <div class="row">
                    <div class="col-sm-3">
                        <h6 class="mb-0">Broj slobodnih dana</h6>
                    </div>
                    <div class="col-sm-9 text-secondary">
                        {{ (employee.vacation_days) }}
                    </div>
                </div>

                <hr>
                <div class="row">
                    <div class="col-sm-3 change-data" v-if="user.role === 'ADMIN'">
                        <button data-bs-toggle="modal" @click="openUpdateModal" type="button"
                            class="btn btn-dark"><font-awesome-icon icon="user"></font-awesome-icon> Promena
                            informacija</button>
                    </div>

                    <div class="col-sm-3 change-password" v-if="user.role === 'ADMIN' || (user.id === employee.id)">
                        <button data-bs-toggle="modal" data-bs-target="#modalChangePassword" type="button"
                            class="btn btn-dark"><i class="fas fa-unlock"></i> Promena lozinke</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { Modal } from 'bootstrap';

import ModalDateHistory from '@/components/modals/ModalDateHistory';
import ModalChangePassword from '@/components/modals/ModalChangePassword';
import EmployeesService from '@/service/EmployeesService.js';
import UtilService from '@/service/UtilService.js';
import ModalAddEditEmployee from '@/components/modals/ModalAddEditEmployee';

export default {

    name: "ProfileInfo",

    components: {
        ModalDateHistory,
        ModalChangePassword,
        ModalAddEditEmployee
    },

    data() {
        return {
            employee: {
                user: {}
            },

            modalDateHistory: null,
            modalChangePassword: null,

            employeeForHistory: {},
            dataName: "",
            historyData: {},
            changePasswordData: {
                oldPassword: "",
                newPassword: "",
                newPasswrodConfirm: ""
            },

            warnings: {},

            employeeForUpdate: {
                user: {}
            },
            modalEditEmployee: null,

            absenceStats: null,
        }
    },

    computed: {
        ...mapState([
            'user'
        ]),

        vacation_progress() {
            if (this.absenceStats) {

                let sickness = this.absenceStats.sickness;
                let vacation = this.absenceStats.vacation + this.absenceStats.other;

                let total = this.employee.vacation_days
                if (vacation > total) {
                    total = vacation
                }


                total += sickness

                let free = total - sickness - vacation

                return {
                    total: total,
                    vacation: vacation,
                    sickness: sickness,
                    free: free,

                    vacation_percent: (vacation / total) * 100,
                    sickness_percent: (sickness / total) * 100,
                    free_percent: (free / total) * 100
                }
            }
            return null
        }

    },

    async created() {
        await this.loadEmployee();
        this.checkWarnings();
        this.getAbsenceStats();

    },

    mounted() {
        this.modalChangePassword = new Modal(document.getElementById('modalChangePassword'));
        this.modalEditEmployee = new Modal(document.getElementById('modalEditEmployee'));
    },

    methods: {

        formatDate: UtilService.formatDate,
        translateRole: UtilService.translateRole,
        translateTransportType: UtilService.translateTransportType,
        checkEmployeeWarning: UtilService.checkEmployeeWarning,

        async loadEmployee() {
            await EmployeesService.getOneEmploeeByUsername(this.$route.params.username)
                .then((response) => { this.employee = response.data; });
        },

        checkWarnings() {
            this.warnings = this.checkEmployeeWarning(this.employee);
        },

        openUpdateModal() {
            this.employeeForUpdate = JSON.parse(JSON.stringify(this.employee));
            this.modalEditEmployee.show();
        },

        async updateEmployee() {
            let form = document.getElementById("formEditEmployee");
            if (this.validateUpdateForm(form) === false)
                return;

            await EmployeesService.updateEmployee(this.employeeForUpdate)
                .then(async response => {
                    this.modalEditEmployee.hide();
                    await this.loadEmployee();
                    this.checkWarnings();
                    this.toast("Zaposleni " + response.data.user.first_name + " " + response.data.user.last_name + " je uspešno izmenjen!", "info");
                }).catch((error) => { this.toast(error.message, 'error'); });
        },


        async getAbsenceStats() {
            const params = {
                employee_id: this.employee.id
            }
            await EmployeesService.getAbsenceStats(params)
                .then(response => {
                    this.absenceStats = response.data;
                })
                .catch((error) => { alert(error.message); });
        },

        validateForm(form) {
            form.classList.add('was-validated');
            if (this.changePasswordData.newPassword !== this.changePasswordData.newPasswordConfirm) {
                this.toast("Lozinke se ne podudaraju", "error")
                return false;
            }
            return form.checkValidity();
        },

        validateUpdateForm(form) {
            form.classList.add('was-validated');
            return form.checkValidity();
        },

        changePassword() {
            let form = document.getElementById("formChangePassword");
            if (this.validateForm(form) == false)
                return;

            let data = {
                "old_password": this.changePasswordData.oldPassword,
                "new_password": this.changePasswordData.newPassword,
            }
            EmployeesService.updatePassword(this.employee.user.id, data)
                .then(() => { this.toast("Lozinka je uspešno promenjena"); this.modalChangePassword.hide() })
                .catch(() => this.toast("Stara lozinka nije dobro unešena", "error"))
        },

        setDataAndShowHistoryView(employee, historyData) {
            this.employeeForHistory = employee.user.first_name + ' ' + employee.user.last_name
            this.historyData = historyData;
            for (let el of this.historyData) {
                if (el.data) el.data = UtilService.formatDate(el.data);
                else el.data = "/";
                el.history_date = UtilService.formatDateTime(el.history_date);
            }
            this.modalDateHistory = new Modal(document.getElementById('modalDateHistory'));
            this.modalDateHistory.show();
        },
        showHistoryForWorkStartDate(employee) {
            EmployeesService.getWorkStartDateHistory(employee.id)
                .then(response => {
                    this.dataName = "Datum početka rada";
                    this.setDataAndShowHistoryView(employee, response.data);
                })
                .catch((error) => { alert(error.message); });
        },
        showHistoryForApplicationStartDate(employee) {
            EmployeesService.getApplicationStartDateHistory(employee.id)
                .then(response => {
                    this.dataName = "Datum prijave";
                    this.setDataAndShowHistoryView(employee, response.data);
                })
                .catch((error) => { alert(error.message); });
        },
        showHistoryForApplicationEndDate(employee) {
            EmployeesService.getApplicationEndDateHistory(employee.id)
                .then(response => {
                    this.dataName = "Datum važenja prijave";
                    this.setDataAndShowHistoryView(employee, response.data);
                })
                .catch((error) => { alert(error.message); });
        },
        showHistoryForWorkEndDate(employee) {
            EmployeesService.getWorkEndDateHistory(employee.id)
                .then(response => {
                    this.dataName = "Datum kraja rada";
                    this.setDataAndShowHistoryView(employee, response.data);
                })
                .catch((error) => { alert(error.message); });
        },
        showHistoryForMedicalExaminationDate(employee) {
            EmployeesService.getMedicalExaminationDateHistory(employee.id)
                .then(response => {
                    this.dataName = "Lekarski pregled";
                    this.setDataAndShowHistoryView(employee, response.data);
                })
                .catch((error) => { alert(error.message); });
        },
        showHistoryForHealthCardExpirationDate(employee) {
            EmployeesService.getHealthCardExpirationDateHistory(employee.id)
                .then(response => {
                    this.dataName = "Zdravstvena";
                    this.setDataAndShowHistoryView(employee, response.data);
                })
                .catch((error) => { alert(error.message); });
        },
        showHistoryForReligiousHoliday(employee) {
            EmployeesService.getReligiousHolidayHistory(employee.id)
                .then(response => {
                    this.dataName = "Verski praznik";
                    this.setDataAndShowHistoryView(employee, response.data);
                })
                .catch((error) => { alert(error.message); });
        },
        getExpiredText(days) {
            if (days >= 0)
                return "Ističe za " + days + " dana.";
            else
                return "Isteklo pre " + Math.abs(days) + " dana";
        },
        toast(message, type) {
            this.$toasted.show(message, {
                type: type,
                theme: "toasted-primary",
                position: "top-center",
                duration: 3000
            });
        },
    }

}
</script>

<style scoped>
.category {
    text-align: right;
    width: 70px;
    height: auto;
}

.change-password {
    margin-top: 2%;
}

.row.warning-row {
    background: rgb(255, 240, 30);
}

.row.danger-row {
    background: rgb(255, 79, 79);
}

.row.danger-row,
.row.danger-row span {
    color: white;
    font-weight: bold;
}
</style>