<template>
  
    <!-- Modal -->
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="employeeFormModalLabel">Radnik: {{ employee }}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <!-- Modal body -->
                <div class="table-responsive">
                    <table>
                        <thead>
                            <tr>
                                <th>Datum i vreme promene</th>
                                <th>{{ this.dataName }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-bind:key="index" v-for="(el, index) in historyData">
                                <td>{{ el.history_date }}</td>
                                <td><span v-if="el.data !== null">{{ el.data }}</span><span v-else>/</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

</template>


<script>

    export default {
        name: 'ModalDateHistory',
        props: ['employee', 'dataName', 'historyData'],
    }
    
</script>

<style scoped>

table {
    border-collapse: separate;
    border-spacing: 10px 2px;
}

.table-title {
    padding-bottom: 15px;
    background: #299be4;
    color: #fff;
    padding: 16px 30px;
    margin: 0 -25px 10px;
    border-radius: 3px 3px 0 0;
}

.table-title h2{
    margin: 5px 0 0;
    font-size: 1.8em;
}

.table-title .btn {
    color: #566787;
    float: right;
    font-size: 13px;
    background: #fff;
    border: none;
    min-width: 50px;
    border-radius: 2px;
    border: none;
    outline: none !important;
    margin-left: 10px;
}

.table-title .btn:hover, .table-title .btn:focus {
    color: #566787;
    background: #f2f2f2;
}

.table-title .btn .btn-title {
    margin-top: 20px;
}

.table-wrapper {
    min-width: 1000px;
    background: #fff;
    padding: 20px 25px;
    border-radius: 3px;
    box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
}


.table td, .table th {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

table.table-striped tbody tr:nth-of-type(odd) {
    background-color: #fcfcfc;
}
.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0,0,0,.05);
}

table.table-striped.table-hover tbody tr:hover {
    background: #f5f5f5;
}
table.table thead {
    font-size: 0.8em
}


</style>